<template>
  <ion-page>
    <ion-header class="header"
      ><ion-text> {{ $t('crop_image') }}</ion-text></ion-header
    >
    <ion-content>
      <vue-cropper
        ref="cropper"
        :src="src"
        alt="Source Image"
        :aspect-ratio="1 / 1"
        :minContainerWidth="400"
        :minContainerHeight="400"
      >
      </vue-cropper>
    </ion-content>
    <ion-footer>
      <div class="d-flex justify-center mt-2 mx-3">
        <div class="d-flex align-center">
          <ion-button class="font-size-12" color="primary" @click="zoom(0.2)">
            <ion-icon slot="icon-only" :icon="addCircleOutline" class="" />
          </ion-button>
          <ion-button class="font-size-12" color="primary" @click="zoom(-0.2)">
            <ion-icon slot="icon-only" :icon="removeCircleOutline" class="" />
          </ion-button>
        </div>
        <div class="d-flex ml-auto">
          <ion-button class="font-size-12 fw-bold" color="danger" fill="clear" @click="$emit('close-modal')">
            {{ $t('cancel') }}
          </ion-button>
          <ion-button class="font-size-12 fw-bold" color="primary" fill="clear" @click="cropImage">
            {{ $t('save') }}
          </ion-button>
        </div>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonButton, IonIcon, IonText } from '@ionic/vue';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    IonButton,
    IonIcon,
    IonText,
    VueCropper
  },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      img: null
    };
  },
  setup() {
    return {
      addCircleOutline,
      removeCircleOutline
    };
  },
  emits: ['close-modal', 'crop-image'],
  methods: {
    cropImage() {
      const cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit('crop-image', cropImg);
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    }
  }
};
</script>

<style scoped>
.header {
  text-align: center;
  padding: 16px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background: var(--ion-color-primary);
}
</style>
